<template>
  <div>
    <Breadcrumbs :items="crumbs" />
      <div class="white">
      <v-container>
        <div class="display-3">
          <span class="thin">About</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Tech</span><span class="thin">Centrix</span>
        </div>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="mb-2">TechCentrix Solutions is a global technology provider delivering a full suite of operational, business intelligence, and analytics services to manage a connected deployment, optimize customer engagements, and provide real-time marketing for consumer brands. The TechCentrix Solutions patented technology can be used to personally program any packaging display through its Bluetooth capability.</div>
            <div class="mb-2">Our suite of web-based visual tools enables consumer brands to create beautiful messages across all platforms and send dynamic marketing campaigns to individual users—no coding, SQL or new builds required.</div>
            <div class="mb-2">Our current patents covering the technology include: labels, displays, consumer data, and utility functionality: a technology stack which allows integrated real-time data tracking and analytics; the display firmware; mobile Apps available for both IOS and Google Play; and, back-end cloud server technology.</div>
          </v-flex>
        </v-layout>
      </v-container>
      </div>
	</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'About Us',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Breadcrumbs,
  },
}
</script>
